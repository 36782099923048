import React from "react";
import HeroNoImage from "../components/hero-no-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PortableText from "../components/portableText";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";

import "../styles/layout.scss";

export const query = graphql`
  query FettsyretestPageQuery {
    about: sanityFattyacid {
      _rawAcidTestBody
      title
    }
  }
`;

const FatAcidPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const pageData = (data || {}).about;

  return (
    <Layout>
      <SEO title="Fettsyretest" />
      <HeroNoImage />
      <Container>
        <div className="fettsyretest">
          <h1>{pageData.title}</h1>
          <div className="fettsyretest__flex">
            <div className="fettsyretest__text-container">
              {pageData && <PortableText blocks={pageData._rawAcidTestBody} />}
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default FatAcidPage;
